import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import Layout from "../components/Layout"
import { Helmet } from "react-helmet"
import { addAnalysisTool } from "../components/Layout/analytics-tool"



const Pricing = () => {
  const [planID, setPlanID] = useState(1)
  const tabs = [
    { name: "Monthly", price: "15", id: 0 },
    { name: "🔥 Annually", price: "12", id: 1 },
  ]

  const competeProducts = [
    {
      title: "One link for all connections",
      price: 9,
      list: [
        { name: "Linktree", icon: "/assets/pricingImg/logo_linktree.png" },
        { name: "Beacons", icon: "/assets/pricingImg/logo_beacons.png" },
      ],
    },
    {
      title: "Video meeting",
      price: 14,
      list: [
        { name: "Zoom", icon: "/assets/pricingImg/logo_zoom.png" },
        { name: "Google Meet", icon: "/assets/pricingImg/logo_googleMeet.png" },
        { name: "Webex Meeting", icon: "/assets/pricingImg/logo_webex.png" },
      ],
    },
    {
      title: "Chat messages",
      price: 12,
      list: [
        { name: "Slack", icon: "/assets/pricingImg/logo_slack.png" },
        {
          name: "Microsoft Teams",
          icon: "/assets/pricingImg/logo_microsoftTeams.png",
        },
        { name: "WhatsApp", icon: "/assets/pricingImg/logo_whatsapp.png" },
      ],
    },
    {
      title: "Automated scheduling & Online bookings",
      price: 16,
      list: [
        { name: "Calendly", icon: "/assets/pricingImg/logo_calendly.png" },
        {
          name: "Acuity Scheduling",
          icon: "/assets/pricingImg/logo_acuity.png",
        },
      ],
    },
  ]

  const competeProductsMobileData = [
    {
      title: "One link, connects all",
      price: 9,
      list: [
        { name: "Linktree", icon: "/assets/pricingImg/logo_linktree.png" },
        { name: "Beacons", icon: "/assets/pricingImg/logo_beacons.png" },
      ],
    },
    {
      title: "Video Meeting",
      price: 14,
      list: [
        { name: "Zoom", icon: "/assets/pricingImg/logo_zoom.png" },
        { name: "Google Meet", icon: "/assets/pricingImg/logo_googleMeet.png" },
      ],
    },
    {
      title: "Message",
      price: 12,
      list: [
        { name: "Slack", icon: "/assets/pricingImg/logo_slack.png" },
        {
          name: "Microsoft Teams",
          icon: "/assets/pricingImg/logo_microsoftTeams.png",
        },
      ],
    },
    {
      title: "Appointment Scheduling",
      price: 16,
      list: [
        { name: "Calendly", icon: "/assets/pricingImg/logo_calendly.png" },
        {
          name: "Acuity",
          icon: "/assets/pricingImg/logo_acuity.png",
        },
      ],
    },
  ]

  useEffect(() => {
    const observedTarget = document.getElementById("pricingTrialBtn")
    const showTarget = document.getElementById("freeTrialBtn")
    const observer = new IntersectionObserver((entries, observer) => {
      if (window.innerWidth > 600) {
        return
      }
      if (entries[0].intersectionRatio >= 0.01) {
        showTarget.style.setProperty("opacity", "0")
      } else {
        showTarget.style.setProperty("opacity", "1")
      }
    })
    if (observedTarget) {
      observer.observe(observedTarget)
    }
  })
  addAnalysisTool()

  return (
    <>
      <Layout>
      <Helmet htmlAttributes={{ lang: "en" }} title={'Pricing - Virtual Office Software - Cubo'}>
      <meta name="description" content={'Try Cubo - virtual office software free for 7 days or upgrade to our Premium plan and get access to fully customized, superior features.'} />
      <link rel="canonical" href='https://cubo.to/pricing'/>
    </Helmet>
        <div className="pricing-page">
          <h1 className="pricing-page_title">
            <span className="yellowBG">Try Cubo Free </span>
            {/* <span>for 7 days</span> */}
          </h1>

          <div className="pricing-page_subTitle">
          Manage and Monetize your Online Business with Cubo
          </div>

          <div className="pricing-page_tabs">
            {tabs.map((item, index) => (
              <div
                className={
                  index === planID
                    ? "pricing-page_tab active"
                    : "pricing-page_tab"
                }
                key={index}
                onClick={() => setPlanID(index)}
              >
                {item.name}
              </div>
            ))}
            <div className="pricing-page_tag">
              Save
              <span>20</span>%
            </div>
          </div>

          <div className="pricing-page_price">
            $<div className="price">{tabs[planID].price}</div>/month
          </div>

          <a href="/register" style={{ textDecoration: "none",margin:'0 auto'}} id='pricingTrialBtn'>
            <div className="pricing-page_btn">
              {/* <div className="btn_top">Get Started</div>
              <div className="btn_bottom">Try Free for 7 days</div> */}
              <div className="btn_top">👉&nbsp;&nbsp;Start for Free</div>
            </div>
          </a>

          {/* <div className="pricing-page_btn_letter">
          Easy set up. Cancel anytime. 
          </div> */}

          <div className="pricing-page_subTitle hiddenOnMobile" style={{ marginTop: "64px" }}>
          Simple pricing. Great value.
          </div>

          <div className="pricing-page_main">
            <div className="left">
              <img
                src="/assets/icons/pricing-logo.png"
                alt="pricing-logo"
                className="left_logo"
                width={96}
                height={96}
              />
              <div className="left_title">Cubo Online Office</div>
              <div className="left_price">
                $<span> {tabs[planID].price}</span>/month
              </div>
              <a href="/register" style={{textDecoration:'none'}}>
              <div className="left_btn">Start your Free Trial</div>
              </a>
            </div>

            <img src="/assets/icons/arrow_pay.png" alt="arrow" className="arrow" />

            <div className="right">
              {competeProducts.map((item, index) => (
                <div className="right_item" key={index}>
                  <div className="title">
                    {item.title}
                    <span>${item.price} /month</span>
                  </div>
                  <div className="list">
                    <span className="list_text">Replaces</span>
                    {item.list.map((item, index) => (
                      <div className ="list_item" key={index}>
                        <img src={item.icon} alt="icon" width={16} height={16} />
                        <span> {item.name} </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mobile_product_list hiddenOnDesktop">
 
            <div className="container">
              {competeProductsMobileData.map((item, index) => (
                <div className={`container_item ${index === 3 ? 'blackLine':''} `} key={index}>
                  <div className="title">
                    {item.title}
                    <span>${item.price} /mo</span>
                  </div>
                  <div className="list">
                    <span className="list_text">Replaces</span>
                    {item.list.map((item, index) => (
                      <div className ='list_item' key={index}>
                        <img src={item.icon} alt={item.name} width={16} height={16} />
                        <span> {item.name} </span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <div  className="tips">
                <div style={{marginBottom:' 14px'}}>
                <span style={{color:'#999'}}>✕ What You'd Spend Otherwise</span> 
                <span style={{textDecoration: 'line-through',color:'#EE5F5F'}}>$51</span> 
                </div>
                <div style={{color:'#5856D6',fontWeight:700, }}> 
                   <span  >🔥All In Cubo Online Office</span> 
                   <span>$<span style={{fontSize:'32'}}>{tabs[planID].price}</span>/mo</span> 
                </div>
              </div>
            </div>
          </div>


          <div className="pricing-page_subTitle hiddenOnMobile" style={{ marginTop: "64px" }}>Fully-featured Premium Plan</div>

          <div className="pricing-page_mobileSubTitle"  >Fully-featured Premium Plan</div>

    <div className="pricing-page_content">
      <div className="pricing-page_content_item hiddenOnMobile" >
        <div className="pricing-page_content_title">One Simple Link</div>
        <div className="pricing-page_content_text">A customized link address for your online business<br/> with <span style={{color:" #5856D6"}}>Cubo.to/Name</span></div>
      </div>

      <div className="pricing-page_content_item">
        <div className="pricing-page_content_title">Video Meeting  <img className= 'hiddenOnDesktop' src ='/assets/icons/checkbox.png' alt='checkbox'/> </div>
        <div className="pricing-page_content_text" style={{textAlign: 'left',lineHeight: 2} }>
          <span className="text">
            <img src="/assets/pricingImg/icon_tapchat.png" alt="icon_tapchat" />
            <span>Tapchat (1-on-1 real-time video vhat)</span>
          </span>

          <div className="line">
            <span className="text">
              <img src="/assets/pricingImg/icon_meeting.png" alt="icon_meeting" />
              <span>Group meeting</span>
            </span>

            <span className="text">
              <img src="/assets/pricingImg/icon_lock.png" alt="icon_lock" />
              <span>Room lock</span>
            </span>
          </div>

          <div className="line">
            <span className="text">
              <img src="/assets/pricingImg/icon_listen.png" alt="icon_listen" />
              <span>Listen in</span>
            </span>

            <span className="text">
              <img src="/assets/pricingImg/icon_share.png" alt="icon_share" />
              <span>Screen sharing</span>
            </span>
          </div>

          <span className="text">
            <img src="/assets/pricingImg/icon_email.png" alt="icon_email" />
            <span>Notifications (app and email)</span>
          </span>
        </div>
      </div>

      <div className="pricing-page_content_item hiddenOnDesktop" >
        <div className="pricing-page_content_title">One Simple Link <img   src ='/assets/icons/checkbox.png' alt="checkbox"  /> </div>
        <div className="pricing-page_content_text">A customized link address for your online business with <span style={{color:" #5856D6",fontWeight:'bold'}}>Cubo.to/Name</span></div>
      </div>

      <div className="pricing-page_content_item">
        <div className="pricing-page_content_title">Chat Message <img className= 'hiddenOnDesktop' src ='/assets/icons/checkbox.png' alt="checkbox"  /></div>
        <div className="pricing-page_content_text">
          <p>Instant Message</p>
          <p>Automated Message</p>
          <p>Notifications (app and email)</p>
        </div>
      </div>

      <div className="pricing-page_content_item">
        <div className="pricing-page_content_title">Scheduling <img className= 'hiddenOnDesktop' src ='/assets/icons/checkbox.png' alt="checkbox" /></div>
        <div className="pricing-page_content_text">
          <p>Appointment scheduling</p>
          <p>Booking slots</p>
          <p>Agenda view</p>
          <p>Reminders (app and email)</p>
        </div>
      </div>

      <div className="pricing-page_content_item">
        <div className="pricing-page_content_title">Visitors <img className= 'hiddenOnDesktop' src ='/assets/icons/checkbox.png' alt="checkbox" /></div>
        <div className="pricing-page_content_text">
          <p>Working status</p>
          <p>Visitors’ analytics</p>
          <p>Visitors’ leads (Email address)</p>
          <p>Export contact information</p>
        </div>
      </div>

      <div className="pricing-page_content_item">
        <div className="pricing-page_content_title">Personal Branding <img className= 'hiddenOnDesktop' src ='/assets/icons/checkbox.png' alt="checkbox"  /></div>
        <div className="pricing-page_content_text">
          <p>Add Social Media</p>
          <p>Add Websites</p>
          <p>Add File Links</p>
          <p>Add Videos</p>
        </div>
      </div>
    </div>

    <div className="pricing-page_footer">
      <div className="pricing-page_footer_item">
        <div className="title">Customer Support </div>
        <div className="text">    <img src="/assets/pricingImg/normal_check.svg" alt="check" />Email Support</div>
        <div className="text">   <img src="/assets/pricingImg/normal_check.svg"alt="check"  />Chat Support</div>
      </div>

      <div className="pricing-page_footer_item">
        <div className="title">Application</div>
        <div className="text">   <img src="/assets/pricingImg/normal_check.svg"alt="check" />macOS</div>
        <div className="text">   <img src="/assets/pricingImg/normal_check.svg" alt="check"  />Windows</div>
      </div>

      <div className="pricing-page_footer_item">
        <div className="title">Browser Support</div>
        <div className="text">   <img src="/assets/pricingImg/normal_check.svg" alt="check" />Chrome</div>
        <div className="text">   <img src="/assets/pricingImg/normal_check.svg" alt="check"  />Safari</div>
        <div className="text">   <img src="/assets/pricingImg/normal_check.svg" alt="check" />Microsoft Edge</div>
      </div>
    </div>


        </div>
      </Layout>
    </>
  )
}

export default Pricing
